
import { defineComponent } from 'vue';
import RoundButton from '@/components/RoundButton.vue';
import Button from '@/components/Button.vue';
import PatentService from '@/services/patent.service';
import { ExtendedPatent } from '@/models/ExtendedPatent';
import { DocumentInformation } from '@/models/DocumentInformation';
import DocumentService from '@/services/document.service';
import Attachment from '@/components/Attachment.vue';
import Chip from '@/components/Chip.vue';
import { Patent } from '@/models/Patent';

/**
 * View which is responsible for displaying the /patent page for a selected patent
 */
export default defineComponent({
    name: 'Patent',
    components: { RoundButton, Attachment, Button, Chip },
    data() {
        return {
            documents: null as DocumentInformation[] | null,
            documentService: new DocumentService(),
            explorationAvailable: false,
            noDocuments: false,
            patentService: new PatentService(),
        };
    },
    computed: {
        /**
         * Returns the patentId taken from the query parameter 'patentId' of the current URL
         */
        patentId(): string {
            return (this.$route.query.patentId as string) || '';
        },
        /**
         * Returns the searchTerms from the store
         */
        searchTerms(): string[] {
            return this.$store.state.searchTerms;
        },
        /**
         * Returns the extended patent from the store
         */
        patent(): ExtendedPatent {
            return this.$store.state.extendedPatents[this.patentId];
        },
        /**
         * Returns the patent family from the store
         */
        family(): Patent[] {
            return this.$store.state.patentFamilies[this.patentId];
        },
        /**
         * Returns the saved patents count from the store
         */
        savedPatentsCount(): string {
            const items = Object.keys(this.$store.state.savedPatents).length;
            if (items === 0) {
                return '';
            }

            return items.toString();
        },
    },
    async created() {
        // check the url to make sure the query params are applied
        this.checkUrl();

        // if no patent is available (after a refresh or sharing the link) the patent should be loaded
        if (!this.patent) {
            await this.loadPatent();
        }

        // if no family is available (after a refresh or sharing the link) it should be loaded
        if (!this.family) {
            await this.loadFamily();
        } else {
            this.explorationAvailable = true;
        }

        // load documents for the patent
        await this.loadDocuments();
    },
    methods: {
        /**
         * Attempts to take the user back to the previous page
         */
        goBack(): void {
            this.$router.back();
        },

        /**
         * Highlights keywords in a text
         * @param text
         * @param keywords
         */
        highlightText(text = '', keywords = [] as string[]) {
            const pattern = new RegExp(`(${keywords.join('|')})`, 'gi');
            return text.replace(pattern, (match) => {
                return '<mark style="background-color:rgba(245, 255, 129, 1)">' + match + '</mark>';
            });
        },

        /**
         * Opens the saved page
         */
        openSavePage(): void {
            this.$router.push({ path: '/saved' });
        },

        /**
         * Loads a patent from the backend
         */
        async loadPatent() {
            // if no patent id is present we can return
            if (!this.patentId) return;

            this.$store.commit('SHOW_LOADING_BAR');

            try {
                const patent = await this.patentService.get(this.patentId);

                this.$store.commit('STORE_PATENT', { patent, searchTerms: this.searchTerms });
            } catch (err) {
                console.error(err);
            }

            this.$store.commit('HIDE_LOADING_BAR');
        },

        /**
         * Loads the family of the current patent
         */
        async loadFamily() {
            // if no exploration button should be shown we don't need to load the data
            const extPatent = this.patent as ExtendedPatent;

            if (!extPatent) return;

            this.$store.commit('SHOW_LOADING_BAR');

            try {
                const family = await this.patentService.queryFamily(this.patentId);
                this.$store.commit('STORE_FAMILY', { patentId: this.patentId, family });
                this.explorationAvailable = true;
            } catch (err) {
                console.error(err);
                this.explorationAvailable = false;
            }

            this.$store.commit('HIDE_LOADING_BAR');
        },

        /**
         * Opens the document view and passes the document as a query parameter
         * @param document  The document that should be opened
         */
        openDocument(document: DocumentInformation) {
            const routeData = this.$router.resolve({
                name: 'Document',
                query: {
                    document: btoa(JSON.stringify(document)),
                    patentId: (this.patent as ExtendedPatent).patent.id,
                    page: 1,
                },
            });

            window.open(routeData.href, '_blank');
        },

        /**
         * Loads the documents for the current patent from the backend
         */
        async loadDocuments() {
            this.$store.commit('SHOW_LOADING_BAR');

            try {
                this.documents = await this.documentService.query(this.patentId);
            } catch (err) {
                this.noDocuments = true;
                console.error(err);
            }

            this.$store.commit('HIDE_LOADING_BAR');
        },

        /**
         * Opens the exploration mode for a patent
         */
        openExploration() {
            const patent = this.patent as ExtendedPatent;

            this.$store.commit('STORE_PATENT', patent);

            this.$router.push({
                path: '/explore',
                query: { patentId: patent.patent.id, searchTerms: patent.searchTerms },
            });

            // finally hide the dialog mask since otherwise it would blur the exploration mode
            this.$store.commit('HIDE_DIALOG_MASK');
        },

        /**
         * Checks the url for query parameters and adds them to the state
         */
        checkUrl() {
            let searchTerms = this.$route.query.searchTerms;

            // if no patentId or search terms go back to landing page
            if (!this.patentId || !searchTerms) {
                this.$router.push('/');
            }

            if (!(searchTerms instanceof Array)) {
                searchTerms = [searchTerms];
            }

            this.$store.commit('SET_SEARCH_TERMS', searchTerms);
        },
    },
});
